import { template as template_9bed656bd1494c8aba6bdc612a34127f } from "@ember/template-compiler";
const FKControlMenuContainer = template_9bed656bd1494c8aba6bdc612a34127f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
